// src/pages/Resume.jsx
import { motion } from "framer-motion";
import React from "react";
function Resume({ setShowNavbar, setActiveTab }) {
  const resumePdfDe = `${process.env.PUBLIC_URL}/lebenslauf_sina_behdadkia.pdf`;
  const resumePdfEn = `${process.env.PUBLIC_URL}/lebenslauf_sina_behdadkia_eng.pdf`;
  React.useEffect(() => {
    setShowNavbar(true);
    setActiveTab("resume");
    window.scrollTo(0, 0); // Scroll to the top when switching tabs
  }, [setShowNavbar, setActiveTab]);
  return (
    <motion.section
      className="min-h-screen flex flex-col pt-24 px-8 bg-transparent text-white"
      initial={{ opacity: 0, y: 50 }} // Starting values
      animate={{ opacity: 1, y: 0 }} // Final values after animation
      transition={{ duration: 1 }} // Duration of the animation
    >
      {" "}
      <h1 className="text-4xl font-bold mb-12">Sina Behdadkia - Resume</h1>
      {/* Summary Section */}
      <div className="p-6 mb-8">
        <h2 className="text-2xl font-bold mb-4">Summary</h2>
        <p>
          Dynamic and results‑oriented software developer with a solid
          background in machine learning and computer vision. Proven experience
          in developing microservices and automation solutions, with expertise
          in isolated sign language recognition using 3D-CNNs. Aiming to
          leverage extensive machine learning knowledge to return to a position
          as a Machine Learning Engineer and drive innovation.
        </p>
      </div>
      {/* Main Two-Column Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column */}
        <div>
          {/* Work Experience */}
          <div className="p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4">Work Experience</h2>
            <ul className="list-disc pl-5 space-y-4">
            <li>
                <span className="font-bold">Machine Learning Reply</span> -
                Full‑time Machine Learning Engineer (Jan 2025 ‑ Present)
                <ul className="pl-5 list-disc space-y-2">
                  <li>
                  Developed and deployed machine learning models to solve complex business problems, enhancing decision-making processes.
                  </li>
                  <li>
                  Collaborated with cross-functional teams to integrate AI solutions into existing systems, improving efficiency and performance.

                  </li>
                  <li>
                  Conducted data analysis and preprocessing to ensure high-quality inputs for model training, leading to more accurate predictions.

                  </li>
                  <li>
                  Implemented continuous integration and deployment (CI/CD) pipelines for machine learning projects, streamlining the development lifecycle.
                  </li>
                  <li>
                  Provided technical guidance and mentorship to junior team members, fostering a culture of learning and innovation.
                  </li>
                </ul>
              </li>
              <li>
                <span className="font-bold">Simplicity Networks GmbH</span> -
                Full‑time Software Developer (May 2022 ‑ Present)
                <ul className="pl-5 list-disc space-y-2">
                  <li>
                    Developed serverless, event-driven microservices, reducing
                    server costs by 20%.
                  </li>
                  <li>
                    Utilized AWS services (Lambda, S3, DynamoDB) for application
                    development and deployment.
                  </li>
                  <li>
                    Managed CI/CD processes with Jenkins, GitLab CI/CD, reducing
                    deployment time by 15%.
                  </li>
                  <li>
                    Automated logistics processes using event‑driven
                    architecture in Python.
                  </li>
                  <li>
                    Ensured code quality through design patterns and SOLID
                    principles.
                  </li>
                  <li>
                    Integrated Snowflake for data analysis, ensuring accurate
                    reporting.
                  </li>
                  <li>
                    Conducted unit and integration tests using Pytest for code
                    robustness.
                  </li>
                </ul>
              </li>

              <li>
                <span className="font-bold">NTT DATA Deutschland GmbH</span> -
                Full‑time Data Scientist (Nov. 2021 ‑ Apr. 2022)
                <ul className="pl-5 list-disc space-y-2">
                  <li>
                    Developed AI solutions using deep learning and NLP (SpaCy,
                    NER).
                  </li>
                  <li>
                    Built demo prototypes and data pipelines with Python,
                    TensorFlow, and Scikit‑Learn.
                  </li>
                  <li>
                    Analyzed complex data sources using Python and SQL,
                    accelerating decision‑making by 15%.
                  </li>
                  <li>
                    Advised on AI and ML strategies for client projects to
                    implement solutions.
                  </li>
                </ul>
              </li>

              <li>
                <span className="font-bold">Inovex GmbH</span> - Master’s Thesis
                (Mar. 2021 ‑ Sep. 2021)
                <ul className="pl-5 list-disc space-y-2">
                  <li>
                    Developed a sign language recognizer using 3D-CNNs with
                    47,933 RGB‑D videos.
                  </li>
                  <li>
                    Improved recognition accuracy by 15% using 3D‑CNN models and
                    transfer learning.
                  </li>
                  <li>
                    Compared 3D-CNN models with CNN-RNN combinations in terms of
                    accuracy.
                  </li>
                  <li>
                    Implemented hyperparameter tuning and applied modern deep
                    learning techniques.
                  </li>
                </ul>
              </li>

              <li>
                <span className="font-bold">CM4all GmbH</span> - Working
                Student, Software QA Tester (Jan. 2019 ‑ Feb. 2021)
                <ul className="pl-5 list-disc space-y-2">
                  <li>
                    Performed integration tests and automated test cases for
                    increased efficiency by 25%.
                  </li>
                  <li>
                    Onboarded ticket creation in Jira for efficient bug tracking
                    and resolution.
                  </li>
                  <li>
                    Developed functional tests to ensure system stability before
                    production releases.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* Right Column */}
        <div>
          {/* Education Section */}
          <div className="p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4">Education</h2>
            <ul className="list-disc pl-5 space-y-4">
              <li>
                <span className="font-bold">
                  Cologne University of Applied Sciences
                </span>{" "}
                - Master of Science in Computer Engineering (Mar. 2019 ‑ Oct.
                2021)
                <p>
                  Specialization in Machine Learning and Computer Vision.
                  Master’s Thesis: 3D-CNN-based sign language recognizer.
                </p>
              </li>
              <li>
                <span className="font-bold">
                  IAUB University, Boroujerd, Iran
                </span>{" "}
                - Bachelor of Science in Electrical Engineering (Apr. 2010 ‑
                Jul. 2012)
              </li>
              <li>
                <span className="font-bold">
                  Lorestan University, Khorramabad, Iran
                </span>{" "}
                - Associate Degree in Electrical Electronics (Apr. 2006 ‑ Sep.
                2009)
              </li>
            </ul>
          </div>

          {/* Skills Section */}
          <div className="p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4">Skills</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <span className="font-bold">Machine Learning:</span> Deep
                Learning, CNNs, RNNs, NLP (BERT, SpaCy)
              </li>
              <li>
                <span className="font-bold">Programming Languages:</span> Python
                (Advanced)
              </li>
              <li>
                <span className="font-bold">Frameworks and Libraries:</span>{" "}
                TensorFlow, Keras, Scikit‑Learn, OpenCV
              </li>
              <li>
                <span className="font-bold">Cloud & DevOps:</span> AWS (S3,
                Lambda), Docker, CDK, CDKTF
              </li>
              <li>
                <span className="font-bold">CI/CD Tools:</span> Jenkins, GitLab,
                Bitbucket
              </li>
              <li>
                <span className="font-bold">Databases:</span> NoSQL (DynamoDB),
                SQL (Snowflake)
              </li>
            </ul>
          </div>

          {/* Certifications Section */}
          <div className="p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4">Certifications</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>Certified React Developer</li>
              <li>AWS Certified Solutions Architect</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Download Buttons for English and German Resumes */}
      <div className="mt-auto flex justify-center space-x-4 pb-6">
        <a
          href={resumePdfDe}
          className="bg-green-500 text-black py-3 px-6 rounded-lg shadow-lg hover:bg-green-600 transition transform hover:scale-105"
          download
        >
          Resume herunterladen
        </a>
        <a
          href={resumePdfEn}
          className="bg-green-500 text-black py-3 px-6 rounded-lg shadow-lg hover:bg-green-600 transition transform hover:scale-105"
          download
        >
          Download Resume
        </a>
      </div>
    </motion.section>
  );
}

export default Resume;
